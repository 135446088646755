import Layout from "../../containers/Layouts/Layout";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import configs from "config";
import NoImage from "assets/imgs/nomedia.png";
import Loader from "components/Loader";
import { Link } from "react-router-dom";
import {
  CartContainer,
  Header,
  HeaderTitle,
  CartTable,
  Table,
  TableRow,
  Container,
  TableSeparatorUp,
  TableSeparatorDown,
  Td,
  Button,
  SectionCartao,
} from "./styles";
import InputMask from "react-input-mask";
import ModalAviso from "components/ModalAviso";
import { useNavigate } from "react-router-dom";

const OrderDetails = () => {
  const { id } = useParams();
  const url = `${configs.api}product-order/${id}`;
  const urlConductor = `https://takeme.lansolver.com/api/asset/image/`;
  const [orderProduct, setOrderProduct] = useState([]);
  const [order, setOrder] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [statusOrder, setStatusOrder] = useState("");
  const token = Cookies.get("authToken");
  const name = Cookies.get("name");
  const [valueTotal, setValueTotal] = useState("");
  const [sltID, setSltID] = useState("");
  const [error, setError] = useState("");
  const [modalAvisoVisible, setModalAvisoVisible] = useState(false);
  const [modalAvisoMensagem, setModalAvisoMensagem] = useState("");
  const [orderID, setOrderId] = useState("");
  const [formaPagamento, setFormaPagamento] = useState("");
  const [isPaymentFormVisible, setIsPaymentFormVisible] = useState(false); // Add state for the payment form visibility
  const [cardData, setCardData] = useState({
    holder: "",
    number: "",
    installments: 1,
    expMonth: "",
    expYear: "",
    securityCode: "",
  });

  const renderParcelas = () => {
    const parcelas = [];
    const valorMinimoParcela = 300;
    const maxParcelas = 10;

    for (let i = 1; i <= maxParcelas; i++) {
      let updatedValue = valueTotal.replace(/[.,]/g, '');
      let valorConvertido = updatedValue / 100;
      let valorParcela = valorConvertido / i;
      console.log(valorParcela);
      
      if (valorParcela >= valorMinimoParcela) {
        parcelas.push(
          <option key={i} value={i}>
            {i}x - R$ {valorParcela.toFixed(2)}
          </option>
        );
      }
    }

    if(parcelas.length === 0){
      let updatedValue = valueTotal.replace(/[.,]/g, '');
      let valorConvertido = updatedValue / 100;
      parcelas.push(
        <option key={1} value={1}>
            1x - R$ {valorConvertido.toFixed(2)}
        </option>
      )
    }

    return parcelas;
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const navigate = useNavigate();

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchOrderData = async () => {
      try {
        const headers = {
          Authorization: `Bearer ${token}`,
        };
        const response = await axios.get(url, { headers, params: { name: name } });
        const dados = response.data;
        setOrderProduct(dados.assets);
        setOrder(dados.dados_pai.dados);
        setStatusOrder(dados.dados_pai.dados[0].slt_status);
        setOrderId(
          dados.dados_pai.dados[0].campo_custom_valor
            .filter((valor) => valor.cfd_id === 991)
            .map((valor) => valor.cfv_externalvalue)
            .join(", ")
        );
        setFormaPagamento(
          dados.dados_pai.dados[0].campo_custom_valor
            .filter((valor) => valor.cfd_id === 761)
            .map((valor) => valor.cfv_externalvalue)
            .join(", ")
        );
        setValueTotal(
          dados.dados_pai.dados[0].campo_custom_valor
            .filter((valor) => valor.cfd_id === 793)
            .map((valor) => valor.cfv_externalvalue)
            .join(", ")
        );
        setSltID(dados.dados_pai.dados[0].slt_id);

        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching order data:", error);
      }
    };

    fetchOrderData();
  }, [url, token, name]);
  const handleCardChange = (event) => {
    const { name, value } = event.target;
    setCardData((prevCardData) => ({
      ...prevCardData,
      [name]: value,
    }));
  };
  const reprocessPayment = async () => {
    if (
      statusOrder === "Pagamento Reprovado" &&
      (formaPagamento === "Cartão de Crédito" ||
        formaPagamento === "credit_card")
    ) {
      setIsPaymentFormVisible(!isPaymentFormVisible);
    } else {
      console.log("A forma de pagamento não é elegível para reprocessamento.");
    }
  };


  const renderPaymentForm = () => {
    if (isPaymentFormVisible) {
      return (
        <div>
          <SectionCartao>
            <label>Número do cartão</label>
            <InputMask
              type="text"
              placeholder="2222 2222 2222 2222"
              mask="9999 9999 9999 9999"
              maskChar="_"
              name="number"
              alwaysShowMask={false}
              value={cardData.number}
              onChange={handleCardChange}
            />
            <label>Parcelamento disponíveis</label>
            <select
              name="installments"
              value={cardData.installments}
              onChange={handleCardChange}
              style={{
                borderRadius: '20px',
                fontSize: '12px',
                marginTop: '10px',
                marginBottom: '10px',
                border: '1px solid black',
                backgroundColor: "white",
                width:"95%",
                height:"45px",
              }}
            >
              {renderParcelas()}
            </select>
            <label style={{ display: "flex" }}>Nome Impresso</label>
            <InputMask
              placeholder="Nome Impresso"
              type="text"
              name="holder"
              value={cardData.holder}
              onChange={handleCardChange}
            />
            <div
              style={{
                display: "flex",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "70%",
                }}
              >
                <div>
                  <label>Validade</label>
                </div>
                <div style={{ display: "flex" }}>
                  <InputMask
                    placeholder="Mês"
                    mask="99"
                    style={{
                      marginRight: "15px",
                    }}
                    maskChar="_"
                    alwaysShowMask={false}
                    formatChars={{ 9: "[0-9]" }}
                    name="expMonth"
                    value={cardData.expMonth}
                    onChange={handleCardChange}
                  />
                  <InputMask
                    placeholder="Ano"
                    mask="9999"
                    style={{ marginRight: "15px" }}
                    maskChar="_"
                    alwaysShowMask={false}
                    formatChars={{ 9: "[0-9]" }}
                    name="expYear"
                    value={cardData.expYear}
                    onChange={handleCardChange}
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "22%",
                }}
              >
                <div>
                  <label>CVV</label>
                </div>
                <InputMask
                  placeholder="CVV"
                  mask="999"
                  maskChar="_"
                  name="securityCode"
                  alwaysShowMask={false}
                  formatChars={{ 9: "[0-9]" }}
                  value={cardData.securityCode}
                  onChange={handleCardChange}
                />
              </div>
            </div>
            <Button
              style={{ width: "93%" }}
              onClick={() => handlePaymentSubmit()}
            >
              Pagar
            </Button>
          </SectionCartao>
        </div>
      );
    }
    return null;
  };

  const handlePaymentSubmit = async () => {
    try {
      setIsLoading(true);
      setModalAvisoVisible(false);
      const public_key = await axios.get(configs.api + "public-key");
      const dados = {
        cardPay: {
          holder: cardData.holder,
          securityCode: cardData.securityCode,
          installments: cardData.installments,
        },
        method: "credit_card",
        orderID: orderID,
        value: valueTotal,
        reference_id: sltID,
      };

      const card = window.PagSeguro.encryptCard({
        publicKey: public_key.data.public_key,
        holder: cardData.holder,
        number: cardData.number.replace(/\s/g, ""),
        expMonth: cardData.expMonth,
        expYear: cardData.expYear,
        securityCode: cardData.securityCode,
      });
      const encrypted = card.encryptedCard;
      dados.token_card = encrypted;

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios.post(
        configs.api + "payAgain",
        dados,
        config
      );
      if (response.data.charges[0].description === "CANCELED") {
        setError('error');

      }
        let message = response.data.charges[1].payment_response.message;
        setModalAvisoMensagem(message);
        setModalAvisoVisible(true);
        setIsLoading(false);

    } catch (error) {
      setError('error');
      setModalAvisoMensagem(`Erro ao criar pedido: ${error.response.data.message}`);
      setModalAvisoVisible(true);
      setIsLoading(false);
    }
  };

  const closeModalAviso = () => {
    setModalAvisoVisible(false);
    if(error === ""){
      navigate("/conta?pedido=true");
    }
  };

  const renderTable = () => {
    return (
      <CartTable>
        <Table>
          <thead>
            <TableRow>
              <TableSeparatorUp />
              <th style={{ fontWeight: "700" }}>Item</th>
              <th style={{ fontWeight: "700" }}>Valor</th>
              <TableSeparatorDown />
            </TableRow>
          </thead>
          <tbody>
            {orderProduct.slice(0, 3).map((item) => (
              <TableRow key={item.asset_id}>
                <Td>
                  <img
                    src={
                      item.asset?.foto_principal
                        ? urlConductor + item.asset?.foto_principal
                        : NoImage
                    }
                    alt=""
                  />
                </Td>
                <Td>{item.asset?.nome.replace(/-/g, "")}</Td>
                <Td>
                  {" "}
                  R${" "}
                  {
                    item.preco
                  }
                </Td>
                <TableSeparatorDown />
              </TableRow>
            ))}
            {orderProduct.length > 3 && (
              <TableRow>
                <Td colSpan={3}>
                  <button onClick={() => { }}>Ver mais itens</button>
                </Td>
              </TableRow>
            )}
          </tbody>
          <tfoot>
            {order.map((item) => (
              <TableRow>
                <th>
                  <span style={{ fontWeight: "700" }}>Total: R${" "}
                    {item.campo_custom_valor.map((valor, j) =>
                      valor.cfd_id === 793
                        ? `${((valor.cfv_externalvalue))}`
                        : ""
                    )}
                  </span>
                </th>
                <th><span style={{ fontWeight: "700" }}>Status:</span><span style={{ fontWeight: "700", width: "200px", borderRadius: "20px", color: getStatusColor(item.slt_status) }}> {item.slt_status}</span></th>
                <th>
                  <span style={{ fontWeight: "700" }}>Forma de pagamento:{" "}</span>
                  <span style={{ fontWeight: "700" }}>
                    {item.campo_custom_valor.map((forma_pagamento) =>
                      forma_pagamento.cfd_id === 761
                        ? forma_pagamento.cfv_externalvalue
                        : ""
                    )}
                  </span>
                </th>

                <th style={{ width: "300px", display: windowWidth < 768 ? "none" : "block" }}>
                  <span style={{ fontWeight: "700" }}>Data do pedido:{" "}
                    {item.created_at?.date &&
                      formatDateTime(item.created_at.date)}
                  </span>
                </th>

                <TableSeparatorDown />
              </TableRow>
            ))}
          </tfoot>
        </Table>
        {statusOrder === "Pagamento Aprovado" ? (
          <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "20px" }}>
            <Link
              to="https://wa.me/message/APZHBOCKQT5SE1"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none", color: "#000" }}
              as="div"
            >
              <Button style={{ width: "200px" }}> Solicitar reembolso</Button>
            </Link>
          </div>
        ) : statusOrder === "Pedido Entregue" ? (
          <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "20px" }}>
            <Link
              to="https://wa.me/message/APZHBOCKQT5SE1"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none", color: "#000" }}
              as="div" // Renderiza o Link como uma div
            >
              <Button style={{ width: "200px" }}> Solicitar devolução</Button>
            </Link>
          </div>
        ) : null
        }

      </CartTable>
    );
  };
  const getStatusColor = (status) => {
    switch (status) {
      case "Pagamento Reprovado":
        return "#FF5555";
      default:
        return "#000000";
    }
  };
  return (
    <Layout>
      {modalAvisoVisible && (
        <ModalAviso
          visible={modalAvisoVisible}
          mensagem={modalAvisoMensagem}
          onClose={closeModalAviso}
          showOverlay={true}
        />
      )}
      <Header>
        <HeaderTitle>DETALHES DO PEDIDO</HeaderTitle>
      </Header>
      {isLoading ? (
        <>
          <Loader style={{ display: "flex", alignItems: "center" }} />
        </>
      ) : (
        <Container>
          <CartContainer>
            {renderTable()}
            {statusOrder === "Pagamento Reprovado" &&
              (formaPagamento === "Cartão de Crédito" ||
                formaPagamento === "credit_card") && (
                <Button
                  style={{ width: "20%", marginTop: "10px" }}
                  onClick={() => reprocessPayment()}
                >
                  Reprocessar Pagamento
                </Button>
              )}
          </CartContainer>
          {renderPaymentForm()}
        </Container>
      )}
    </Layout>
  );
};

function formatDateTime(dateTimeString) {
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };

  const dateTime = new Date(dateTimeString);
  return dateTime.toLocaleString("pt-BR", options);
}
export default OrderDetails;
